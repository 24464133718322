// -----------------------------------------------------------------------------
// Main
// -----------------------------------------------------------------------------
.hero {
  position: relative;
  height: 100vh;

  .mobile & {
    height: calc(var(--vh100, 100vh));
  }

  &__bg {
    width: 100%;
  }

  &__cont {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 14vh;
    box-sizing: border-box;
    width: 100%;
    z-index: 1;
  }

  &__h {
    margin-bottom: rem(10);
  }

  &__sub {
    display: block;
    margin-bottom: rem(25);
  }

  &.is-invert {
    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 100%;
      background-color: rgba($gray9, 0.1);
    }

    .hero__h,
    .hero__sub {
      color: $white;
    }
  }
}

.pad {
  overflow: hidden;
  padding: rem(75) 0;

  .lounge-swiper {
    padding-top: 0;
  }

  &--gray {
    background-color: $gray0;
  }

  &--divide {
    padding: 0;

    .main-header {
      margin-bottom: rem(20);

      &__more {
        bottom: 0;
      }
    }
  }
}

.career {
  padding: rem(60) 0;

  .no-result {
    margin-bottom: 0;
  }

  &__item {
    border-top: 1px solid rgba($gray9, 0.2);
  }

  &__link {
    display: flex;
    flex-wrap: wrap;
    padding: rem(18) 0 rem(22);
  }

  &__brow {
    width: 100%;
    margin-bottom: rem(9);
  }

  &__h {
    width: 100%;
    font-weight: 400;
  }

  &__date,
  &__type {
    display: inline-block;
    margin-top: rem(9);
    color: $gray6;
  }

  &__type {
    margin-left: rem(20);
  }
}

.contact {
  padding: rem(60) 0 rem(80);
  @include full-deco(bg, $gray0);

  &__cont {
    padding-top: rem(20);
    border-top: 1px solid rgba($gray9, 0.2);

    & + & {
      padding-top: rem(50);
      border-top: none;
    }
  }

  &__h {
    margin-bottom: rem(10);
  }

  &__desc {
    margin: 0;
    color: $gray6;
    font-size: rem(18);
  }

  &__btn {
    margin-top: rem(15);
  }
}

.banner {
  position: relative;

  &__bg {
    padding-top: 133.33%;
  }

  &__h {
  }

  &__cont {
    position: absolute;
    top: 80px;
    left: 0;
    right: 0;
  }

  &__h {
    color: $white;
  }

  &__func {
    margin-top: rem(30);
  }
}

@include mq(small) {
  .banner {
    &__bg {
      padding-top: 0;
      height: 450px;
    }

    &__cont {
      top: 160px;
    }
  }
}

@include mq(medium) {
  .hero {
    &__cont {
      bottom: 21vh;
    }

    &__sub {
      margin-bottom: rem(40);
    }
  }

  .career {
    &__list {
      border-bottom: 1px solid rgba($gray9, 0.2);
    }

    &__h {
      width: calc(100% - 160px - 50px);
      @include ellipsis();
    }

    &__date {
      margin-top: 0;
      width: 160px;
    }
    &__type {
      margin: 0;
      width: 50px;
    }
  }

  .contact {
    &__cont {
      position: relative;

      &--has-btn {
        padding-right: rem(130);
      }
    }

    &__category {
      margin-top: rem(20);
    }

    &__btn {
      position: absolute;
      top: 10px;
      right: 0;
      width: auto;
    }
  }
}

@include mq(x-large) {
  .pad {
    padding: rem(110) 0;

    .lounge-swiper {
      padding-bottom: rem(20);
    }

    &--divide {
      padding: rem(120) 0;

      & > .l-content {
        display: flex;
      }

      .main-header {
        margin-bottom: 3rem;

        &__h {
          font-size: rem(42);
        }

        &__more {
          bottom: 10px;
        }
      }
    }
  }

  .career,
  .contact {
    box-sizing: border-box;
    padding-top: rem(50);
    padding-bottom: 0;
    width: calc(50% - 30px);
  }

  .contact {
    padding-left: rem(40);
    padding-right: rem(40);
    padding-bottom: rem(40);
    background-color: $gray0;
    margin-left: rem(60);

    &::after {
      display: none;
    }
  }
}

.main-header {
  position: relative;
  margin-bottom: rem(35);

  &__more {
    bottom: 3px;
  }

  @include mq(large) {
    margin-bottom: rem(40);

    &__more {
      bottom: 25px;
    }
  }
}



.tagtext-box__22 {
  display: none;
}

.tagtext__22 {
  margin-right: 4px;
  color: #fff;
  font-size: 1.5rem;
  white-space: pre;
  line-height: 1.6;
}

.tagtext-box__22 {
  line-height: 1.3;
}

.esg__cont__22 {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  padding: 0 2rem 2rem;
}

.esg_banner_Wrap {
  display: flex;
  justify-content: space-between;
  color: #fff;
}
.esg_banner_mn {
  opacity: 50%;
  /* border: red solid 1px; */
}
.esg_banner_select {
  opacity: 100%;
}

.esg_banner_mn:hover {
  opacity: 100%;
}

.esg__h__22 {
  font-size: 2rem;
  margin-bottom: 1rem;
  word-break: keep-all;
  word-wrap: break-word;
}
.esg__h__22 .linkline,
.esg__h__22 .linkline__text,
.esg__h__22 .linkline:hover {
  color: #fff;
}

.esg_banner_select .esg__h__22 .linkline__text {
  @include animation-underline(#fff, 2px);
}

/* ***********   백그라운드 색상 모드 선택  ***********  */
/* ***********   백그라운드 색상 모드 선택  ***********  */

/* ----- 백그라운드 dark 모드 ----- */

.bg-dark {
  background-color: #2B2B31;

  .popup__btn {
    border-color: #FFF;
    color: #FFF;
  }

  .popup__h, .popup__desc {
    color: #FFF;
  }
}

/* ----- 백그라운드 light 모드 ----- */

.bg-light {
  background-color: #D8E4FF;

  .popup__btn {
    border-color: #101820;
    color: #101820;
  }

  .popup__h, .popup__desc {
    color: #101820;
  }
}

/* ***********  END 백그라운드 색상 모드 선택  ***********  */
/* ***********  END 백그라운드 색상 모드 선택  ***********  */

.popup_main {
  position: relative;
  height: 69.5vh;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 40%;
  }
}

.popup__h.h5 {
  font-size: 2.8rem !important;
}

.popup__desc {
  font-size: 1.8rem !important;
}

.popup__func {
  margin-top: 1.6rem;
}

.popup__bg {
  opacity: 0;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.popup__box {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 100%;
  padding-bottom: 9.3vh;
}

.popup__cont {
  width: 100%;
  opacity: 0;
}

@media (min-width: 45em) {
  .popup_main {
    position: relative;
    height: 400px;
    overflow: hidden;
  }
}

@media (min-width: 45em) {
  .popup__box {
    padding: 0.5vh 0 1.15vh;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .popup__cont {
    width: 50%;
    max-width: 640px;
    opacity: 0;
  }

  .popup__desc {
    margin: 2rem 0 4rem;
  }
}
