@charset "UTF-8";
// -----------------------------------------------------------------------------
// Main
// -----------------------------------------------------------------------------

// utility
// -----------------------------------------------------------------------------
@import '../../../../../support/front/scss/util/_icon-char'; // task
@import '../../../../../support/front/scss/util/_variable.scss';
@import '../../../../../support/front/scss/util/_function.scss';
@import '../../../../../support/front/scss/util/_mixin.scss';

// vendor style (npm dependencies)
// -----------------------------------------------------------------------------
@import 'sass-mq/_mq.scss';

// main
// -----------------------------------------------------------------------------
@import '../../../../../support/front/scss/page/_main';

// 재사용 스타일
// -----------------------------------------------------------------------------
//Product > depth 1 style
@import '../../../../../support/front/scss/page/product/_prd-d1';

// Story > lounge-swiper
@import '../../../../../support/front/scss/page/story/_lounge';

// ENG 스타일
// -----------------------------------------------------------------------------

.contact {
  background: none;
  padding: 0;

  &::after {
    display: none;
  }

  &__cont {
    padding-top: 0;
    padding-bottom: rem(40);
    border-top: none;
    border-bottom: 1px solid rgba($gray9, 0.2);
  }

  @include mq(large) {
    width: 100%;
    &__pad {
      display: flex;
    }

    &__cont {
      width: 50%;
      padding-bottom: 0;
      border-bottom: none;

      &--has-btn {
        padding-right: 0;

        .contact__btn {
          position: relative;
        }
      }

      &:nth-child(2) {
        padding-top: 0;
        padding-left: rem(40);
        border-left: 1px solid rgba($gray9, 0.2);
      }
    }
  }

  @include mq(x-large) {
    margin-left: 0;
  }
}



.esg_container {
  display: block;
  position: relative;
  padding-top: 133.12%;

  .linkblock__img {
    transition: 0.4s ease-out;
    background-size: cover;
  }

  .linkblock__img.environment {
    background-image: url('/eng/image/common/main/esg_slider_sm_01.jpg');
  }

  .linkblock__img.social {
    background-image: url('/eng/image/common/main/esg_slider_sm_02.jpg');
  }

  .linkblock__img.governance {
    background-image: url('/eng/image/common/main/esg_slider_sm_03.jpg');
  }

  .no-touchevents & {
    &:hover,
    &:focus {
      .linkblock__img {
        transform: scale(1.1);
      }
    }
  }
}

@media (min-width: 820px) {
  .tagtext-box__22 {
    display: block;
  }
  .esg__h__22 {
    font-size: 3rem;
  }
  .esg__cont__22 {
    padding: 0 6rem 5.5rem;
  }
  .esg__h__22 {
    margin-bottom: rem;
  }

  .esg_container {
    padding-top: 0;
    height: 635px;

    .linkblock__img.environment {
      background-image: url('/eng/image/common/main/esg_slider_lg_01.jpg');
    }

    .linkblock__img.social {
      background-image: url('/eng/image/common/main/esg_slider_lg_02.jpg');
    }

    .linkblock__img.governance {
      background-image: url('/eng/image/common/main/esg_slider_lg_03.jpg');
    }
  }
}

@media (min-width: 45em) {
  .tagtext-box__22 {
    display: block;
    margin-top: 20px;
  }

  .esg_container {
    padding-top: 0;
    height: 635px;

    .linkblock__img.environment {
      background-image: url('/eng/image/common/main/esg_slider_lg_01.jpg');
    }

    .linkblock__img.social {
      background-image: url('/eng/image/common/main/esg_slider_lg_02.jpg');
    }

    .linkblock__img.governance {
      background-image: url('/eng/image/common/main/esg_slider_lg_03.jpg');
    }
  }
}
