// -----------------------------------------------------------------------------
// Story > LL Lounge
// -----------------------------------------------------------------------------

.lounge {
  position: relative;
  padding-top: 133.12%;

  & + & {
    margin-top: rem(40);
  }

  .tagtext,
  &__h,
  &__date {
    color: $white;
  }

  &__link {
    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 70%;
      @include gradient(
        '0deg',
        rgba($gray9, 0.3),
        rgba($gray9, 0.2),
        rgba($gray9, 0)
      );
    }
  }

  &__cont {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    padding: 0 rem(20) rem(20);
  }

  &__h {
    display: block;
    margin-bottom: rem(10);
    @include break-word;
  }

  &__date {
    display: block;
    margin-top: rem(15);
    font-size: rem(15);
  }

  @include mq(medium) {
    padding-top: 0;
    height: 635px;
  }

  @include mq(large) {
    & + & {
      margin-top: rem(60);
    }

    &__cont {
      padding: 0 rem(60) rem(55);
    }

    &__h {
      margin-bottom: rem(10);
    }

    &__date {
      margin-top: rem(20);
    }
  }
}

.lounge-swiper {
  @include mq($until: large) {
    .swiper {
      &-wrapper {
        display: block;
      }

      &-pagination-progressbar {
        display: none;
      }
    }

    &__controller {
      display: none;
    }
  }

  @include mq($until: large) {
    .lounge {
      &:nth-child(n + 4) {
        display: none;
      }
    }
  }

  @include mq(large) {
    overflow: visible;

    .lounge {
      & + .lounge {
        margin-top: 0;
      }

      &-list {
        padding-top: 0;
      }
    }

    .swiper {
      &-pagination-progressbar {
        width: calc(100% - 170px);
        height: 4px;
        margin-top: rem(50);
        background-color: rgba($gray9, 0.2);

        &-fill {
          background-color: $gray9;
        }
      }
    }

    &__controller {
      position: absolute;
      bottom: -25px;
      right: -25px;
      display: flex;
      align-items: center;

      .swiper-button {
        &-prev,
        &-next {
          position: static;
          width: 60px;
          height: 60px;
          margin-top: 0;
          display: flex;
          align-items: center;
          justify-content: center;

          &::before {
            font-size: 24px;
          }
        }

        &-prev {
          @include icon('arrow-left');
        }
        &-next {
          @include icon('arrow-right');
        }
      }

      .fraction {
        margin: 0 rem(6);
        font-size: rem(15);
        white-space: nowrap;

        &__length {
          position: relative;
          margin-left: rem(10);

          &::before {
            content: '';
            bottom: 8px;
            left: -13px;
            position: absolute;
            width: 12px;
            height: 1px;
            background-color: $gray;
            transform: rotate(-70deg);
          }
        }
      }
    }
  }
}

.lounge-func {
  margin-top: rem(30);
  text-align: center;

  @include mq(large) {
    margin-top: rem(40);
  }
}
